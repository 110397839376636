import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchProviders, fetchProviderApis } from "../services/apiService";
import {
	SidebarContainer,
	ProviderItem,
	ApiList,
	ApiItem,
} from "../styles/global";

export interface SidebarProps {
	isOpen: boolean;
	setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ApiInfo {
	key: string;
	title: string;
	img?: string;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, setSidebarOpen }) => {
	const [providers, setProviders] = useState<string[]>([]);
	const [expandedProvider, setExpandedProvider] = useState<string | null>(
		null
	);
	const [apis, setApis] = useState<Record<string, ApiInfo[]>>({});
	const navigate = useNavigate();

	// Fetch providers on mount
	useEffect(() => {
		const loadProviders = async () => {
			try {
				const data = await fetchProviders();
				setProviders(data.data);
			} catch (error) {
				console.error("Failed to load providers:", error);
			}
		};
		loadProviders();
	}, []);

	// Handle sidebar click
	const handleProviderClick = async (provider: string) => {
		if (expandedProvider === provider) {
			setExpandedProvider(null);
		} else {
			setExpandedProvider(provider);
			if (!apis[provider]) {
				try {
					const data = await fetchProviderApis(provider);
					const apiInfoList: ApiInfo[] = Object.entries(
						data.apis
					).map(([key, value]: [string, any]) => ({
						key,
						title: value.info.title || key,
						img: value.info["x-logo"]?.url,
					}));
					setApis((prevApis) => ({
						...prevApis,
						[provider]: apiInfoList,
					}));
				} catch (error) {
					console.error(
						`Failed to load APIs for ${provider}:`,
						error
					);
				}
			}
		}
	};

	// Handle navigation on sidebar item click
	const handleApiClick = (provider: string, apiKey: string) => {
		setSidebarOpen(false);
		navigate(`/api/${provider}/${apiKey}`);
	};

	return (
		<SidebarContainer isOpen={isOpen} setSidebarOpen={setSidebarOpen}>
			<h2>Select Provider</h2>
			{providers.map((provider) => (
				<div key={provider}>
					<ProviderItem onClick={() => handleProviderClick(provider)}>
						{provider}
					</ProviderItem>
					{expandedProvider === provider && apis[provider] && (
						<ApiList>
							{apis[provider].map((api) => (
								<ApiItem
									key={api.key}
									onClick={() =>
										handleApiClick(provider, api.key)
									}
								>
									{api.img && (
										<img
											width={20}
											height={20}
											src={api.img}
											alt={api.title}
										/>
									)}
									&nbsp;
									{api.title}
								</ApiItem>
							))}
						</ApiList>
					)}
				</div>
			))}
		</SidebarContainer>
	);
};

export default Sidebar;
