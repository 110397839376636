import axios from "axios";
import { ProviderDetails } from "../interfaces/apis-guru";

const BASE_URL = "https://api.apis.guru/v2";

export const fetchProviders = async () => {
	try {
		const response = await axios.get(`${BASE_URL}/providers.json`);
		return response.data;
	} catch (error) {
		console.error("Error fetching providers:", error);
		throw error;
	}
};

export const fetchProviderApis = async (
	providerName: string
): Promise<ProviderDetails> => {
	try {
		const response = await axios.get(`${BASE_URL}/${providerName}.json`);
		return response.data;
	} catch (error) {
		console.error(`Error fetching APIs for ${providerName}:`, error);
		throw error;
	}
};
