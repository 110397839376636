import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import ApiDetails from "./components/ApiDetails";
import Sidebar from "./components/Sidebar";

const AppContainer = styled.div`
	display: flex;
	height: 100vh;
	width: 100vw;
	margin-bottom: 3rem;
`;

const ContentContainer = styled.div`
	flex-grow: 1;
	padding: 20px;
	transition: margin-left 0.3s ease-in-out;
`;

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const SidebarButton = styled.button`
	background-color: #007bff;
	color: white;
	border: none;
	padding: 10px 15px;
	cursor: pointer;
	border-radius: 5px;
`;

const App: React.FC = () => {
	const [sidebarOpen, setSidebarOpen] = useState(false);

	const toggleSidebar = () => {
		setSidebarOpen(!sidebarOpen);
	};

	return (
		<Router>
			<AppContainer>
				<Sidebar isOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
				<ContentContainer>
					<Routes>
						<Route path="/" />
						<Route
							path="/api/:provider/:api"
							element={<ApiDetails />}
						/>
					</Routes>
					<ButtonContainer>
						<SidebarButton onClick={toggleSidebar}>
							{sidebarOpen ? "Close Sidebar" : "Open Sidebar"}
						</SidebarButton>
					</ButtonContainer>
				</ContentContainer>
			</AppContainer>
		</Router>
	);
};

export default App;
