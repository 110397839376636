import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchProviderApis } from "../services/apiService";
import {
	DetailsContainerStyle,
	DetailsDesc,
	DetailsHeader,
	DetailsItem,
	DetailsLink,
	DetailsTitle,
} from "../styles/global";
import { API } from "../interfaces/apis-guru";

// Show API Details
const ApiDetails: React.FC = () => {
	const { provider, api } = useParams<{ provider: string; api: string }>();
	const [apiDetails, setApiDetails] = useState<API>();

	useEffect(() => {
		const loadApiDetails = async () => {
			if (provider && api) {
				try {
					const data = await fetchProviderApis(provider);
					setApiDetails(data.apis[api]);
				} catch (error) {
					console.error(
						`Failed to load API details for ${provider}/${api}:`,
						error
					);
				}
			}
		};
		loadApiDetails();
	}, [provider, api]);

	if (!apiDetails) {
		return <div>Loading...</div>;
	}

	return (
		<DetailsContainerStyle>
			<DetailsHeader>
				{apiDetails.info["x-logo"]?.url && (
					<img
						src={apiDetails.info["x-logo"]?.url}
						alt={apiDetails.info.title}
					/>
				)}
				<DetailsTitle>{apiDetails.info.title}</DetailsTitle>
			</DetailsHeader>

			<DetailsItem>Description</DetailsItem>
			<DetailsDesc>{apiDetails.info.description}</DetailsDesc>

			<DetailsItem>Swagger</DetailsItem>
			<DetailsLink href={apiDetails.swaggerUrl}>
				{apiDetails.swaggerUrl}
			</DetailsLink>
		</DetailsContainerStyle>
	);
};

export default ApiDetails;
