import styled from "styled-components";
import { SidebarProps } from "../components/Sidebar";

// ApiDetails
export const DetailsContainerStyle = styled.div`
	padding: 5rem;
`;

export const DetailsHeader = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 4rem;
`;

export const DetailsTitle = styled.h1`
	margin-left: 2rem;
`;

export const DetailsItem = styled.h5`
	font-weight: 500;
	font-size: 2rem;
	margin-top: 2rem;
	margin-bottom: 1rem;
`;

export const DetailsDesc = styled.span`
	font-weight: 400;
`;

export const DetailsLink = styled.a`
	font-weight: 400;
	color: #fff;
`;

// Sidebar

export const SidebarContainer = styled.div<SidebarProps>`
	width: 300px;
	background-color: #6e859c;
	padding: 20px;
	height: 100vh;
	overflow-y: auto;
	position: fixed;
	left: ${(props) => (props.isOpen ? "0" : "-300px")};
	transition: left 0.3s ease-in-out;
`;

export const ProviderItem = styled.div`
	margin-bottom: 10px;
	cursor: pointer;
`;

export const ApiList = styled.ul`
	list-style-type: none;
	padding-left: 0;
`;

export const ApiItem = styled.li`
	margin-bottom: 5px;
	cursor: pointer;
	display: flex;
	align-items: center;

	&:hover {
		text-decoration: underline;
	}
`;
